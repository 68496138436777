import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "submitBtn", "processingFlag"]

  connect() {
    this.toggleSubmitBtn()
  }

  processingFlagTargetConnected() {
    this.toggleSubmitBtn()
  }

  processingFlagTargetDisconnected() {
    this.toggleSubmitBtn()
  }

  submit() {
    this.submitBtnTarget.click()
  }

  toggleSubmitBtn() {
    const isProcessing = this.hasProcessingFlagTarget
    const inputIsEmpty = this.inputTarget.value.length <= 0
    this.submitBtnTarget.disabled = isProcessing || inputIsEmpty
  }
}
