import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileList", "fileTemplate"]

  filesChange(dropzoneEvent) {
    for (const file of dropzoneEvent.detail.files) {
      const listItem = this._prepareItem(file)
      this.fileListTarget.appendChild(listItem)
    }
  }

  _prepareItem(file) {
    const item = this.fileTemplateTarget.content.cloneNode(true)

    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(file)

    const fileInput = item.querySelector(".file-input")
    fileInput.files = dataTransfer.files

    const fileNameElement = item.querySelector(".file-name")
    if (fileNameElement) fileNameElement.innerHTML = file.name

    const titleInput = item.querySelector(".title-input")
    if (titleInput) titleInput.value = this._createTitle(file.name)

    return item
  }

  _createTitle(fileName) {
    if (fileName === null || fileName === '') return fileName

    let title = fileName.toString()

    title = title.replace(/\.[^.]+$/, "") // Remove file extension.
    title = title.replace(/[-_]+/g, " ") // Replace all dashes and underscores with a space.
    title = title.charAt(0).toUpperCase() + title.slice(1) // Uppercase first letter.

    return title
  }
}
