import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  connect() {
    // Flag to preserve scroll position after user scrolled up.
    this.preserveScroll = false

    // Flag to skip scroll event processing after auto scrolling to bottom.
    this.ignoreNextScroll = false

    // Observe content resize event.
    this.observer = new ResizeObserver(this._onContentResize.bind(this))
    this.observer.observe(this.element)

    // Observe element scroll event.
    const scrollEventElement = this._getScrollEventElement()
    scrollEventElement.addEventListener("scroll", this._onElementScroll.bind(this))

    // Scroll to bottom on initial load.
    this._scrollToBottom()
  }

  disconnect() {
    // Remove resize observer.
    if (this.observer) this.observer.unobserve(this.element)

    // Remove scroll event handler.
    const scrollElement = this._getScrollElement()
    if (scrollElement) scrollElement.removeEventListener("scroll", this._onElementScroll)
  }

  _onElementScroll() {
    // Ignore scrolls generated by ResizeObserver
    if (this.ignoreNextScroll) {
      this.ignoreNextScroll = false
      return
    }

    // Preserve scroll position based on offset from bottom
    const scrollElement = this._getScrollElement()
    const scrollBottomOffset = scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight
    this.preserveScroll = scrollBottomOffset >= 10
  }

  _onContentResize() {
    if (!this.preserveScroll) this._scrollToBottom()
  }

  _scrollToBottom() {
    this.ignoreNextScroll = true

    const scrollElement = this._getScrollElement()
    scrollElement.scrollTop = scrollElement.scrollHeight
  }

  _getScrollEventElement() {
    if (this.hasIdValue) {
      return document.getElementById(this.idValue)
    } else {
      return document
    }
  }

  _getScrollElement() {
    if (this.hasIdValue) {
      return document.getElementById(this.idValue)
    } else {
      return document.scrollingElement
    }
  }
}
