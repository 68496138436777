import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog"]

  show() {
    this.dialogTarget.showModal()
    this.dialogTarget.returnValue = null
  }

  confirm() {
    this.dialogTarget.close("confirm")
  }

  cancel() {
    this.dialogTarget.close("cancel")
  }

  close() {
    this.dispatch(
      this.dialogTarget.returnValue === "confirm" ? "confirm" : "cancel"
    )
  }
}
