import { Application } from "@hotwired/stimulus"

import { appsignal } from "../appsignal"
import { installErrorHandler } from "@appsignal/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Install Appsignal error handler
if (appsignal) installErrorHandler(appsignal, application);

export { application }
