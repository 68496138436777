import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["toggler", "tooltip", "arrow"]
  static classes = ["inactive"]

  connect() {
    this.popper = null
  }

  disconnect() {
    this.hide()
  }

  show() {
    this._destroyPopper()

    this.popper = createPopper(this.togglerTarget, this.tooltipTarget, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, 12] },
        },
        {
          name: 'arrow',
          options: { element: this.arrowTarget }
        }
      ],
    })

    this.tooltipTarget.classList.remove(this.inactiveClass)
  }

  hide() {
    this.tooltipTarget.classList.add(this.inactiveClass)
    this._destroyPopper()
  }

  _destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
