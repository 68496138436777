import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  visit() {
    const select = this.selectTarget

    const option = select.options[select.selectedIndex]
    const url = option.dataset.url

    if (url) Turbo.visit(url)
  }
}
