import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["placeholder", "message"]
  static classes = ["inactive"]

  messageTargetConnected() {
    this._togglePlaceholder()
  }

  messageTargetDisconnected() {
    this._togglePlaceholder()
  }

  _togglePlaceholder() {
    const lastMessage = this.messageTargets[this.messageTargets.length - 1]
    const showPlaceholder = lastMessage.dataset.messagePlaceholderShow === "true"
    this.placeholderTarget.classList.toggle(this.inactiveClass, !showPlaceholder)
  }
}
