// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "chartkick/chart.js"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "./appsignal"
import "./controllers"

// Send current nonce with each Turbo request.
document.addEventListener('turbo:before-fetch-request', (event) => {
  const metaNonce = document.querySelector("meta[name='csp-nonce']").getAttribute("content")
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = metaNonce
})
