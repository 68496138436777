import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitBtn"]
  connect() {
    this.originalDisabledValue = false

    this.element.addEventListener("direct-uploads:start", this._onStart.bind(this))
    this.element.addEventListener("direct-uploads:end", this._onEnd.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("direct-uploads:start", this._onStart)
    this.element.removeEventListener("direct-uploads:end", this._onEnd)
  }

  _onStart() {
    this.originalDisabledValue = this.submitBtnTarget.disabled
    this.submitBtnTarget.disabled = true
  }

  _onEnd() {
    this.submitBtnTarget.disabled = this.originalDisabledValue
  }
}
