import Appsignal from "@appsignal/javascript"

import { plugin as pluginBreadcrumbsConsole } from "@appsignal/plugin-breadcrumbs-console"
import { plugin as pluginBreadcrumbsNetwork } from "@appsignal/plugin-breadcrumbs-network"
import { plugin as pluginWindowEvents } from "@appsignal/plugin-window-events"

let appsignal = null

if (window.settings.appsignalFrontendKey) {
  const appsignalOptions = { key: window.settings.appsignalFrontendKey }

  if (window.settings.appsignalRevision) {
    appsignalOptions.revision = window.settings.appsignalRevision
  }

  appsignal = new Appsignal(appsignalOptions)

  appsignal.use(pluginBreadcrumbsConsole())
  appsignal.use(pluginBreadcrumbsNetwork())
  appsignal.use(pluginWindowEvents())
}

export { appsignal }
