import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectorWrapper", "imageWrapper", "image", "fileInput", "purgeInput"]
  static classes = ["hidden"]
  static values = { emptyImageSrc: String }

  filesChange(dropzoneEvent) {
    const file = dropzoneEvent.detail.files[0]

    if (file) {
      this.imageTarget.src = URL.createObjectURL(file)
      this._toggleSelectorVisibility(false)

      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      this.fileInputTarget.files = dataTransfer.files

      if (this.hasPurgeInputTarget) this.purgeInputTarget.checked = false
    }
  }

  purgeFile() {
    this._toggleSelectorVisibility(true)
    this.imageTarget.src = this.emptyImageSrcValue

    this.fileInputTarget.files = null

    if (this.hasPurgeInputTarget) this.purgeInputTarget.checked = true
  }

  _toggleSelectorVisibility(visible) {
    this.selectorWrapperTarget.classList.toggle(this.hiddenClass, !visible)
    this.imageWrapperTarget.classList.toggle(this.hiddenClass, visible)
  }
}
