import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"
import iro from '@jaames/iro'

export default class extends Controller {
  static targets = ["toggler", "dropdown", "picker", "input", "preview"]
  static classes = ["inactive"]

  connect() {
    this.popper = null

    this.picker = new iro.ColorPicker(this.pickerTarget)

    this.picker.on("color:change", (color) => {
      this.inputTarget.value = color.hexString
      this._updatePreview(color.hexString)
    })

    this.updateColor()
  }

  disconnect() {
    this.forceHide()
  }

  updateColor() {
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){2}$/
    const color = this.inputTarget.value

    if (hexRegex.test(color)) {
      this.picker.color.hexString = color
      this._updatePreview(color)
    }
  }

  toggle() {
    this._destroyPopper()

    if (!this.dropdownTarget.classList.toggle(this.inactiveClass)) {
      this.popper = createPopper(this.togglerTarget, this.dropdownTarget, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, 6] },
          },
        ],
      })
    }
  }

  hide(event) {
    if (
      this.dropdownTarget.contains(event.target) === false &&
      this.togglerTarget.contains(event.target) === false
    ) {
      this.forceHide()
    }
  }

  forceHide() {
    this._destroyPopper()
    this.dropdownTarget.classList.add(this.inactiveClass)
  }

  _destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }

  _updatePreview(color) {
    this.previewTarget.style.backgroundColor = color
  }
}
