import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["widthInput", "heightInput", "codeArea"]

  connect() {
    this.codeTemplate = this.codeAreaTarget.value
    this.update()
  }

  update() {
    let width = this.widthInputTarget.value
    let height = this.heightInputTarget.value

    width = width ? `${width}px` : "100%"
    height = height ? `${height}px` : "100%"

    this.codeAreaTarget.value = this.codeTemplate.replace("{{width}}", width).replace("{{height}}", height)
  }
}
