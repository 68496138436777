import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selector", "fileInput"]
  static classes = ["selectorActive", "selectorInactive"]

  connect() {
    this.dragCounter = 0;

    this.allowMultiple = this.fileInputTarget.multiple
    this.fileTypes = this.fileInputTarget.accept.split(",").map(x => x.trim()).filter(x => x.length > 0)
  }

  dragoverDoc(event) { event.preventDefault() }
  dragleaveDoc(event) { event.preventDefault() }
  dropDoc(event) { event.preventDefault() }

  dragenterSelector(event) {
    event.preventDefault()

    this.dragCounter++
    this._toggleSelectorActivity(true)
  }

  dragleaveSelector(event) {
    event.preventDefault()

    this.dragCounter--
    if (this.dragCounter === 0) this._toggleSelectorActivity(false)
  }

  dropSelector(event) {
    event.preventDefault()

    this.dragCounter = 0
    this._toggleSelectorActivity(false)

    if (this._isDroppable(event.dataTransfer.files)) {
      this.fileInputTarget.files = event.dataTransfer.files
      this.fileChange()
    }
  }

  openSelect() {
    this.fileInputTarget.click()
  }

  fileChange() {
    this.dispatch("change", {
      detail: { files: this.fileInputTarget.files }
    })
  }

  _toggleSelectorActivity(active) {
    if (active) {
      this.selectorTarget.classList.add(...this.selectorActiveClasses)
      this.selectorTarget.classList.remove(...this.selectorInactiveClasses)
    } else {
      this.selectorTarget.classList.remove(...this.selectorActiveClasses)
      this.selectorTarget.classList.add(...this.selectorInactiveClasses)
    }
  }

  _isDroppable(files) {
    if (!this.allowMultiple && files.length !== 1) return false

    if (this.fileTypes.length > 0) {
      for (const file of files) {
        const isAllowedType = this.fileTypes.includes(file.type) ||
          this.fileTypes.includes(`${file.type.split('/')[0]}/*`)

        if (!isAllowedType) return false
      }
    }

    return true
  }
}
